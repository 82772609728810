import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import GoogleMap from "../components/google-maps"

const contact = () => {

  const title_style=`inline-block border-b-2 border-zinc-700 text-white
  font-bold text-3xl md:text-4xl pb-1 my-4`
  const div_style=`w-full border-yellow-500 border-2 p-10
  mt-10 mb-14 md:max-w-7xl text-base md:text-lg text-slate-400`
  const p_style=`py-1`

  const image =
    <StaticImage
    className="!absolute top-0 bottom-0 left-0 right-0 z-0 opacity-60"
    imgClassName="absolute top-0 bottom-0 left-0 right-0 z-0 "
    src="../../static/mackbook-side.jpg"
    alt="Get in touch"
    placeholder="blurred"
    />

  return (
    <>
      <Seo
      title="Contatti | FvgLab - Come contattare l'azienda informatica per soluzioni personalizzate" 
      description={"Hai bisogno di soluzioni informatiche personalizzate per la tua azienda o per uso personale? Contatta FvgLab per scoprire come possiamo aiutarti a creare il tuo futuro digitale. Visita la pagina dei contatti sul nostro sito per conoscere i nostri recapiti e per richiedere maggiori informazioni."}
      />
      
      <Layout>

        <SimpleBanner title="Get in touch" back={image} />

        <section className={`grid grid-cols-1 
        gap-10 px-10 md:px-20 lg:px-40 py-10 md:py-20 lg:py-40`}>

          <div className={div_style}>
            <h1 className={title_style}>
              La sede
              <span className="text-yellow-300 text-3xl">.</span>
            </h1>
            <p className={p_style}>Viale Trieste, 96 - 33100 Udine, UD</p>
            <p className={p_style}><b>Fisso:</b>
              <a href="tel:04321833017"
              className="text-slate-400 hover:text-yellow-400
              focus:text-yellow-400 duration-300 capitalize pl-1">0432 1833017</a></p>
            <p className={p_style}><b>Mobile:</b> 
              <a href="tel:+393472559733"
              className="text-slate-400 hover:text-yellow-400
              focus:text-yellow-400 duration-300 capitalize pl-1">347 2559733</a>
            </p>
            <p className={p_style}><b>Email:</b> info@fvglab.it</p>
            <p className={p_style}><b>PEC:</b> fvglab@pec.it</p>
          </div>

          {/* <div className={div_style}>
            <h1 className={title_style}>
              Gli orari
              <span className="text-yellow-300 text-3xl">.</span>
            </h1>
            <p className={p_style}>Dal <b>lunedì</b> al <b>venerdì</b>:</p>
            <p className={p_style}>09:00 - 13.00</p>
            <p className={p_style}>14:00 - 18:00</p>
          </div> */}

        </section>

        <section className="px-10 md:px-20 lg:px-40 pb-10 md:pb-32">
          <GoogleMap className="w-full h-[60vh]" />
        </section>
        
        <Contact title={"Lavoriamo assieme!"} object={""} />

      </Layout>
    </>
  )
}

export default contact
