import React from 'react'

/**
 * @title GoogleMap
 * @param {String} className 
 * @returns {React.Component} Iframe element
 * @description Componente React che esporta un iFrame con la mappa di Google Maps con le indicazioni della posizione 
 * dell'azienda. Al momento è stato impostato un iframe ma in futuro si può integrare anche il modulo
 * google-map-react se fosse necessario.
 */


export default function GoogleMap({className}) {
    
    const isClient = typeof window !== 'undefined'
    
    return (
        isClient && (
            <iframe
            title="Google Map FvgLab"
            alt="Google Map FvgLab"
            className={className}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3292.0923467831685!2d13.246528530302186!3d46.06488643991492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a4ababc82a463%3A0x23075c45125a5ced!2sViale%20Trieste%2C%2096%2C%2033100%20Udine%20UD!5e0!3m2!1sit!2sit!4v1714750978817!5m2!1sit!2sit"
            width="100%" height="100%" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" data-mce-fragment="1">
            </iframe>
        )
    )
}